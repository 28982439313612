<template>
  <!-- navigation START -->
  <div id="nav">
    <div class="main-navigation">
      <!-- logo START -->
      <div class="logo logo-centered">
        <router-link :to="'/'">
          <img width="320" height="72" src="@/assets/images/logo-syreta-full.png" />
        </router-link>
      </div>
      <!-- logo END -->
    </div>
  </div>
  <!-- navigation END -->
</template>

<script>
export default {
  name: 'app-navigation',
  data() {
    return {
    };
  },
};
</script>
